/* Style inputs with type="text", select elements and textareas */
input[type=text], input[type=email], select, textarea {
    width: 100%; /* Full width */
    padding: 12px; /* Some padding */ 
    border: 1px solid #ccc; /* Gray border */
    border-radius: 4px; /* Rounded borders */
    box-sizing: border-box; /* Make sure that padding and width stays in place */
    margin-top: 6px; /* Add a top margin */
    margin-bottom: 16px; /* Bottom margin */
    resize: vertical; /* Allow the user to vertically resize the textarea (not horizontally) */
}

  /* Style the submit button with a specific background color etc */
  input[type=submit] {
    background-color: #0093d3;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* When moving the mouse over the submit button, add a darker green color */
  input[type=submit]:hover {
    background-color: #37b1e5;
}
  
  /* Add a background color and some padding around the form */
  .container-form {
      border-radius: 5px;
      text-align: left;
      /* background-color: #f2f2f2; */
      padding: 20px;
      border-style: none;
  }

  .button-form {
    margin-top: 15px;
    width: 100%;
    text-decoration: none;
  }

  textarea:focus, input:focus{
    outline: none;
}
  textarea:focus, input:focus{
    border: 1px solid #0093d3;
}