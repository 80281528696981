
@font-face {
  font-display:swap;
  font-family: 'Lato';
  src: 
       url('./fonts/lato-bold-webfont.woff2') format('woff2'),
       url('./fonts/lato-bold-webfont.woff') format('woff');
  unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd
       U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-weight: 700;
  font-style: normal;
}


@font-face {
  font-display:swap;
  font-family: 'Lato';
  src:  
       url('./fonts/lato-regular-webfont.woff2') format('woff2'),
       url('./fonts/lato-regular-webfont.woff') format('woff');
  unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd
          U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display:swap;
  font-family: 'Open Sans';
  src:  

       url('./fonts/open_sans/opensans-bold-webfont.woff2') format('woff2'),
       url('./fonts/open_sans/opensans-bold-webfont.woff') format('woff');
  unicode-range:U+00??,U+0131,U+0152-0153,U+02bb-02bc,U+02c6,U+02da,U+02dc,U+2000-206f,U+2074,U+20ac,U+2122,U+2191,U+2193,U+2212,U+2215,U+feff,U+fffd
          U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;

  font-weight: 700;
  font-style: normal;
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Lato';
}

a, abbr, acronym, address, applet, article, aside, audio, b, big, blockquote, body, canvas, caption, center, cite, code, dd, del, details, dfn, div, dl, dt, em, embed, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, output, p, pre, q, ruby, s, samp, section, small, span, strike, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}

body {
 
  padding: 0;
  line-height: 1.6;
  font-family: Lato;
  font-size: 13px;
  cursor: default;

  min-height: 100%;
  
  
  background-color: #1a1b26;
  -webkit-font-smoothing: antialiased;
  

}

button {
  height: 44px;
  width: 90%;
  color: #1a1b26;
  border-radius: 5px ;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  transition: 0.3s;
}

button:hover {opacity: 1}

svg {
  opacity: 0.8;
  transition: 0.2s;
}

svg:hover {opacity: 1}

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: inherit;
  -moz-font-smoothing: inherit;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}


.select-css {
  display: block;
  font-size: 12px;
  font-family: 'Verdana';
  font-weight: 400;
  color: #444;
  line-height: 1;
  padding: .4em 1em .3em .8em;
  width: 80px;
  max-width: 100%; 
  box-sizing: border-box;
 margin: 20px auto;
  border: 1px solid #aaa;
  box-shadow: 0 1px 0 1px rgba(0,0,0,.03);
  border-radius: 5px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#f7f7f7 100%);
  background-repeat: no-repeat, repeat;
  background-position: right .7em top 50%, 0 0;
  background-size: .65em auto, 100%;
}
.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: rgba(59, 153, 252, .7);
}

.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222; 
  outline: none;
}
.select-css option {
  font-weight:normal;
}


/* OTROS ESTILOS*/

