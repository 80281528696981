.pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.App {
 
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Estilo para la barra de desplazamiento */
/* Webkit (Safari, Chrome) */
::-webkit-scrollbar {
  width: 8px; /* Ajusta el ancho de la scrollbar según tus preferencias */
}

::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color de la barra de desplazamiento */
  border-radius: 4px; /* Puntas redondeadas */
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 transparent; /* Color de la barra de desplazamiento y fondo transparente */
}

/* Personaliza el estilo de la barra de desplazamiento al pasar el mouse sobre ella */
/* Webkit (Safari, Chrome) */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Cambia el color al pasar el mouse sobre la barra */
}

/* Firefox */
* {
  scrollbar-color: #555 transparent; /* Cambia el color al pasar el mouse sobre la barra en Firefox */
}

