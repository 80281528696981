
  
  * {
    margin: 0;
    padding: 0;
  }
  

  
  /* Timeline Container */
  .timeline {
   
    margin: 5px 20px auto;
    padding: 10px 20px;
    
  }
  
  /* Outer Layer with the timeline border */
  .outer {
    border-left: 2px solid #333;
    
  }
  
  /* Card container */
  .card {
    position: relative;
    margin: 0 0 20px 20px;
    padding: 10px;
    background: rgba(51, 51, 51, 0.194);
    color: ghostwhite;
    border-radius: 8px;
    opacity: 8;
    cursor: pointer;
    border: 1px solid transparent;
  }

  .card:hover {
    opacity: 1;
    border: 1px solid #1e90ff;
    
  }
  
  /* Information about the timeline */
  .info {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  /* Title of the card */
  .title {
    color: aqua;
    position: relative;
    font-size: 14px;
  }
  
  /* Timeline dot  */
  .title::before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background: gray;
    border-radius: 999px;
    left: -35px;
    bottom: 6px;
  }

  @media screen and (max-width: 575px) {
    .timeline {
   
        padding: 5px 5px;
        
      }
  }