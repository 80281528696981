

.text__link__proyect {
  margin-bottom: 10px;
}

.padding-left {
  margin-left: 5px;
}

.home {
  position: relative;
  padding-top: 0px;
  flex: 1;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
  -webkit-box-flex: 1;

  color: #f0f8ff;

  display: flex;
  justify-content: center;
  min-height: 100%;

}

.container_home {
  padding-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 50px;
}

.content_about {
  background-color: rgba(0, 0, 0, 0.1);
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  max-width: 90%;
  border: none;
  border-radius: 10px;
  font-size: 1em;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 24px;

  color: aliceblue;
}

.social_home {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 10px;
}

h1 {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 2.2em;

  margin: 0 auto 0px auto;
  color: #fff;
  letter-spacing: -2px;

  text-shadow: 0px 1px 3px rgb(0 0 0 / 80%);
  padding-bottom: 3px;
}

.sub_title {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 1.2em;
  text-align: left;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 0;
}

.contact_details {
  display: inherit;
  flex-direction: column;
  text-align: left;
  align-self: center;
  width: 90%;
}

hr {
  width: 90%;
  margin: 0.9em auto;
  border-color: rgba(150, 150, 150, 0.1);
}

h3 {
  font: 1.2em "Lato", sans-serif;
  text-align: left;
  line-height: 20px;
}

#svg-buttom {
  width: 15px;
}

.button_component {
  max-width: 150px;
}

blockquote {
  background: rgba(150, 150, 150, 0.03);
  border-left: 2px solid aliceblue;
  margin: 1.5em 0em;
  padding: 1em 2em;
  font-style: italic;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 2em;
  line-height: 0;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote h3 {
  display: inline;
}

#img_proyects {
  width: 80%;
}

.content__proyects {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

h2 > a {
  color: aqua;
}

.social_icon_home {
  min-width: 40px;
  max-height: 40px;
  cursor: pointer;
  background-color: rgba(19, 19, 19, 0.123);
  border-radius: 25px;
  opacity: 0.6;
  transition: 0.3s;
}

.social_icon_home:hover {
  opacity: 1;
}

/* ESCRITORIO EXTRA GRANDE  */

@media only screen and (min-width: 1200px) {
  .button_download {
    max-width: 250px;
  }
  
  .homeImage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* align-items: center; */
    height: 80%;
  }

  .container_home .content_about h1 {
    font-size: 3em;
    letter-spacing: -2px;
    font-weight: 700;
    font-family: "Open Sans";
  }

  .content_about {
    width: 60%;
  }

  h3 {
    font-size: 1.4em;
    line-height: 1.4em;
  }

  blockquote {
    background: rgba(150, 150, 150, 0.03);
    border-left: 2px solid aliceblue;

    padding: 1em 2em;
    font-style: italic;
  }
}

/* ESCRITORIO GRANDE  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .homeImage {
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    height: 80%;
  }

  .container_home .content_about h1 {
    font-size: 2em;
    letter-spacing: -2px;
    font-weight: 700;
    font-family: "Open Sans";
  }

  .content_about {
    width: 60%;
    margin-top: 10px;
  }

  h3 {
    font-size: 1.4em;
    line-height: 1.4em;
  }
  

  blockquote {
    background: rgba(150, 150, 150, 0.03);
    border-left: 2px solid aliceblue;
    padding: 1em 2em;
    font-style: italic;
  }
}

/****************************** Solo despues de este tamaño ******************************/
@media only screen and (min-width: 990px) {
  .menu-bottom {
      background: transparent !important;
    }
}


/****************************** Escritorio pequeño / tablet ******************************/
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .button_component {
    width: 100%;
    max-width: 90%;

  }

  .padding-left {
    margin-left: 0;
    margin-top: 5px;
  }
}

/****************************** Tablets y phablets ******************************/
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .button_component {
    width: 100%;
    max-width: 90%;    
  }

  .padding-left {
    margin-left: 0;
    margin-top: 5px;
  }
  
}
@media screen and (max-width: 575px) {
  .button_component {
    width: 100%;
    max-width: 90%;
  }
  .padding-left {
    margin-left: 0;
    margin-top: 5px;
  }
  
    .title_principal span{
      font-size: 1.2em;
    }
}


/* Estilos para el contenedor del avatar */
.avatar {
  position: absolute;
  top: -60px;
  right: calc(50% - 60px);
  width: 100px; /* Tamaño del avatar */
  height: 100px;
  border-radius: 50%; /* Para hacerlo circular */
  overflow: hidden; /* Oculta cualquier parte de la imagen que se desborde */

  border: 4px solid transparent; /* Borde transparente para permitir el gradiente */
  background-image: linear-gradient(135deg, #8a2be2, #1e90ff); /* Gradiente de morado eléctrico a azul eléctrico */
  background-origin: border-box; /* Aplica el gradiente al borde */
}

/* Estilos para la imagen del avatar */
.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ajusta la imagen para llenar completamente el contenedor */
}

.container_home.profile .content_about {
  padding-top: 40px;
}

.container_home.about .content_about {
  padding-top: 40px;
  
} 

.content_border {
  height: 100%;
  width: 100%;
  border: 4px solid transparent; /* Borde transparente para permitir el gradiente */
  background-image: linear-gradient(135deg, #8a2be2, #1e90ff); /* Gradiente de morado eléctrico a azul eléctrico */
  background-origin: border-box; /* Aplica el gradiente al borde */
}



@media screen and (max-width: 575px) {
  .avatar {
    width: 80px;
    height: 80px;

    top: -40px;
    right: calc(50% - 40px);
  }

  .container_home.profile .content_about {
    padding-top: 40px;
  }

  .container_home.about .content_about {
    padding-top: 40px;    
  } 

}

.content_about {
  border: 1px solid #1e90ff;
}

.desc_img{
  display: flex;
  flex-direction: row;
}
   /* Estilo base del botón */
.button_download {
  display: inline-block;
  gap: 5px;
  background-color: #1e90ff; /* Color de fondo */
  color: #fff; /* Color del texto */
  border-radius: 5px; /* Borde redondeado */
  text-decoration: none;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
  transition: background-color 0.3s, border 0.3s;
}

