.flex-center {
  display: flex;
  align-items: center;
}

.icon-container {
  width: 20px; /* Ajusta el tamaño deseado */
  height: 20px;
  background-color: #0073e6; /* Puedes cambiar el color de fondo según tus preferencias */
  border-radius: 50%; /* Esto crea un círculo */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Estilo para la imagen del icono */
.icon-container img {

  object-fit: cover; /* Ajusta el tamaño y recorte de la imagen */
}

.navbar {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: translateZ(0);
}

.navbar-header__container {
  display: flex;
  height: 65px;
  width: 90%;

  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

/* el menu entero que se despliega cuando haces click en el menu hamburguesa */

.nav-menu {
  position: absolute;
  box-shadow: 6px 0 15px rgb(0 0 0 / 20%);
  left: -100%;
  transform: translatex(0);
  width: 280px;
  transition: 2s ease-out;

  height: 100%;

  top: 0;
  bottom: 0;

  overflow-y: auto;
  background-color: #1a1b26;
  color: aliceblue;
  overflow: hidden;
}

.nav-menu.active {
  left: 0;
  transition: 500ms;
}

.nav-menu-logo {
  width: 400px;
  opacity: 0.03;
  position: absolute;
  bottom: -40px;
  left: -50px;
}

/* cada icono del menu que se despliega */
.nav-text {
  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 60px;
}

.nav-text:hover {
  background-color: hsla(193, 100%, 50%, 0.05);
  border-left: 1px solid #61dafb53;
}

.nav-menu-items {
  transform: translatex(0);
  align-self: center;
  width: 100%;
}

a.activated {
  color: #61dafb;
}

a {
  color: #fff;
  text-decoration: none;
  text-decoration-line: none;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.nav-menu-items {
  width: 100%;
}

span {
  font-size: 1.4em;
}

/* parte de atras cuando se despliega en menu wue se torna opaca*/
.left-col-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 800;
  background-color: rgba(43, 53, 59, 0.4);
  visibility: hidden;
}

.left-col-backdrop.active {
  visibility: visible;
}

/* div del lado derecho del header, contiene el icon user y cart */
.nav-right {
  position: relative;
  font-size: 21px;
  padding-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-left {
  position: relative;
  padding-right: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav {
  padding-top: 50px;
  display: flex;
  align-items: flex-start;
}

/*  ICONS   */

.iconLogoMenu {
  position: relative;
  min-width: 150px;
}

.icons {
  position: relative;
  min-width: 40px;
  max-height: 40px;
}

/* ESCRITORIO EXTRAGRANDE */

@media only screen and (min-width: 1200px) {
  .navbar-header__container {
    display: flex;
    height: 65px;
    width: 95%;

    justify-content: space-between;
    align-items: center;
  }

  .icons {
    right: 1em;
    position: relative;
    min-width: 50px;
    max-height: 50px;
  }
}

/* ESCRITORIO GRANDE  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .icons {
    right: 1em;
    position: relative;
    min-width: 50px;
    max-height: 50px;
  }
}

/****************************** Escritorio pequeño / tablet ******************************/
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .icons {
    right: 0.9em;
    position: relative;
    min-width: 50px;
    max-height: 50px;
  }

  .nav-menu {
    transition: 1s ease-out;
  }
}

/****************************** Tablets y phablets ******************************/
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .icons {
    right: 0.9em;
    position: relative;
    min-width: 50px;
    max-height: 50px;
  }
  .nav-menu {
    transition: 1s ease-out;
  }
}

@media screen and (max-width: 575px) {
  .icons {
    right: 0.9em;
    position: relative;
    min-width: 50px;
    max-height: 50px;
  }
  .nav-menu {
    transition: 0.5s ease-out;
  }
}
